import { useState } from 'react';
import { FaFacebookF, FaGooglePlusG, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa';

const Footer = () => {
  const [email, setEmail] = useState("");

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://prepmate-3uro.onrender.com/api/mocktests/joinnow', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }), 
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      else{
        alert("your intrest has been successfuly saved")
      }


    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred. Please try again later.");
    }
  };

  return (
    <footer className="bg-[#0d0c0a] py-8 px-4">
      <div className="container mx-auto text-center">
        <h2 className="text-2xl font-bold text-[#f97316] mb-4">Let’s connect!</h2>
        <p className="text-[#fff9f8] mb-4">
          Employers & Recruiters stay up to date with the latest product updates & announcements:
        </p>
        <div className="flex justify-center mb-4">
          <input
            type="email"
            placeholder="type your e-mail here..."
            className="border border-[#fff9f8] rounded-l-lg px-4 py-2 bg-[#0d0c0a] text-[#fff9f8] placeholder-[#fff9f8]/70 focus:outline-none focus:ring-2 focus:ring-orange-500"
            onChange={handleChange}
          />
          <button
            className="bg-red-600 text-white font-semibold rounded-r-lg px-4 py-2 hover:bg-red-700 transition duration-200"
            onClick={handleSubmit} // Corrected the handler name
          >
            Join
          </button>
        </div>
        <div className="flex justify-center space-x-6 mb-4">
          <a href="#" className="text-[#fff9f8] hover:text-orange-500 transition duration-200">
            <FaFacebookF className="h-6 w-6" />
          </a>
          <a href="#" className="text-[#fff9f8] hover:text-orange-500 transition duration-200">
            <FaGooglePlusG className="h-6 w-6" />
          </a>
          <a href="#" className="text-[#fff9f8] hover:text-orange-500 transition duration-200">
            <FaInstagram className="h-6 w-6" />
          </a>
          <a href="#" className="text-[#fff9f8] hover:text-orange-500 transition duration-200">
            <FaLinkedinIn className="h-6 w-6" />
          </a>
          <a href="#" className="text-[#fff9f8]  hover:text-orange-500 transition duration-200">
            <FaTwitter className="h-6 w-6" />
          </a>
        </div>
        <p className="text-xs text-[#fff9f8]/70">PrepMate 2024 © All Rights reserved</p>
        <p className="text-xs text-[#fff9f8]/70">Designed by PrepMate</p>
      </div>
    </footer>
  );
};

export default Footer;

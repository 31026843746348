import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import TestAnalysis from "../testanalysis/TestAnalysis";

const TimeAndClock = () => {
  const location = useLocation();
  const [timeSpent, setTimeSpent] = useState(Array(10).fill(0));
  const [questionStartTime, setQuestionStartTime] = useState(Date.now());
  const courseId = location.state?.courseId;

  const lessonName = location.state?.lessonName || "Test";
  const courseTitle = location.state?.courseTitle || "Unknown";

  const initialTime = 1800; // 30 minutes
  const [userAnswers, setUserAnswers] = useState(() => {
    const savedAnswers = Cookies.get("userAnswers");
    return savedAnswers ? JSON.parse(savedAnswers) : {};
  });

  const [timeRemaining, setTimeRemaining] = useState(() => {
    const savedTime = Cookies.get("timeRemaining");
    return savedTime ? JSON.parse(savedTime) : initialTime;
  });

  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [score, setScore] = useState(0);
  const [accuracy, setAccuracy] = useState(0);
  const [warning, setWarning] = useState(false);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get('https://run.mocky.io/v3/6da8baf8-f2a9-4b42-a5e6-4f79f8866b10');

        setQuestions(response.data.questions);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    fetchQuestions();

    const timer = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 60 && !warning) {
          setWarning(true);
        }
        if (prevTime <= 1) {
          clearInterval(timer);
          handleSubmit();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [warning]);

  useEffect(() => {
    Cookies.set("userAnswers", JSON.stringify(userAnswers), { expires: 1 }); // Expires in 1 day
    Cookies.set("timeRemaining", JSON.stringify(timeRemaining), { expires: 1 });
  }, [userAnswers, timeRemaining]);

  const handleOptionChange = (questionId, answer) => {
    setUserAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: answer,
    }));
  };

  const handleClearResponse = () => {
    setUserAnswers((prevAnswers) => {
      const updatedAnswers = { ...prevAnswers };
      delete updatedAnswers[questions[currentQuestionIndex]?.id];
      return updatedAnswers;
    });
  };

  const handleSubmit = () => {
    const totalScore = questions.reduce(
      (score, question) =>
        score + (userAnswers[question.id] === question.answer ? 1 : 0),
      0
    );

    setScore(totalScore);
    setAccuracy(((totalScore / questions.length) * 100).toFixed(2));
    setSubmitted(true);

    // Clear answers and time from cookies after submission
    Cookies.remove("userAnswers");
    Cookies.remove("timeRemaining");
  };

  const handleNext = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handleBack = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleJumpToQuestion = (index) => {
    setCurrentQuestionIndex(index);
  };

  const renderOptions = () => {
    const currentQuestion = questions[currentQuestionIndex];
    return (
      currentQuestion?.options &&
      Object.entries(currentQuestion.options).map(([key, option], index) => {
        const optionLabel = String.fromCharCode(65 + index);
        return (
          <div key={key} className="flex items-center mb-2">
            <input
              type="radio"
              id={`${currentQuestion.id}-${key}`}
              name={`question-${currentQuestion.id}`}
              value={key}
              checked={userAnswers[currentQuestion.id] === key}
              onChange={() => handleOptionChange(currentQuestion.id, key)}
              className="mr-2"
              aria-label={`Option ${optionLabel} for question ${
                currentQuestionIndex + 1
              }`}
            />
            <label htmlFor={`${currentQuestion.id}-${key}`} className="text-md">
              {optionLabel}. {option}
            </label>
          </div>
        );
      })
    );
  };

  const progress = ((currentQuestionIndex + 1) / questions.length) * 100;

  return (
    <div className="flex flex-col h-screen bg-[#0d0c0a] pt-6">
      <div className="flex justify-between flex-col items-center p-4 text-white">
        {!submitted && (
          <div>
            <h2 className="text-2xl font-bold">{lessonName}</h2>
            <h2 className="text-2xl font-bold">{courseTitle}</h2>

            <p className="mr-2 border-red-200 h-[20px] w-auto">
              Time Remaining: {Math.floor(timeRemaining / 60)}:
              {String(timeRemaining % 60).padStart(2, "0")}
            </p>
            {warning && (
              <span className="text-red-500">Time is running out!</span>
            )}
            <div className="h-2 bg-gray-300 rounded w-full mt-4">
              <div
                className="h-full bg-blue-600 rounded"
                style={{ width: `${progress}%` }}
              />
            </div>
          </div>
        )}
      </div>

      {!submitted ? (
       <div className="flex flex-1 p-4 flex-col lg:flex-row">
       <div className="w-full lg:w-2/3 p-4 bg-[#0d0c0a] rounded-lg shadow-md">
         <h2 className="text-lg font-semibold mb-2">
           Question {currentQuestionIndex + 1}/{questions.length}
         </h2>
         <h3 className="text-lg mb-4">
           {questions[currentQuestionIndex]?.question}
         </h3>
         {renderOptions()}
         <div className="flex justify-between mt-4">
           <button
             type="button"
             onClick={handleBack}
             className={`bg-cyan-200 text-black rounded-2xl py-2 px-4 ${
               currentQuestionIndex === 0
                 ? "bg-gray-400"
                 : "bg-gray-600 hover:bg-gray-700"
             }`}
             disabled={currentQuestionIndex === 0}
           >
             Back
           </button>
           <button
             type="button"
             onClick={handleClearResponse}
             className="rounded-2xl py-2 px-4 bg-yellow-500 hover:bg-yellow-600 text-black font-normal"
           >
             Clear Response
           </button>
           <button
             type="button"
             onClick={handleNext}
             className={`rounded-2xl text-white py-2 px-4 ${
               currentQuestionIndex === questions.length - 1
                 ? "bg-black"
                 : "bg-blue-500 hover:bg-blue-600"
             }`}
           >
             Next
           </button>
         </div>
         <button
           type="button"
           onClick={handleSubmit}
           className="mt-4 bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-2xl"
         >
           Submit Test
         </button>
       </div>

       <div className="w-full lg:w-1/3 p-4">
         <h2 className="text-lg font-semibold mb-2">Question Status</h2>
         <div className="max-h-[400px] overflow-y-auto border border-gray-300 rounded-lg p-2">
           <div className="grid grid-cols-4 gap-4">
             {questions.map((question, index) => (
               <div
                 key={question.id}
                 className={`p-2 rounded-3xl text-center ${
                   userAnswers[question.id]
                     ? "bg-blue-600 text-white"
                     : "border-2 border-red-500 hover:bg-gray-300"
                 }`}
               >
                 <button
                   onClick={() => handleJumpToQuestion(index)}
                   className={`block text-center w-full py-2 px-4 rounded-md`}
                 >
                   {index + 1}
                 </button>
               </div>
             ))}
           </div>
         </div>
       </div>
     </div>
      ) : (
        <TestAnalysis
          score={score}
          accuracy={accuracy}
          userAnswers={userAnswers}
          questions={questions}
          lessonName={lessonName}
          courseId={courseId}
          courseTitle={courseTitle}
        />
      )}
    </div>
  );
};

export default TimeAndClock;

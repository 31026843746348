import React, { useState, useEffect } from "react";
import axios from "axios";
import { BeatLoader } from "react-spinners"; 
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa"; 

const FaqQuery = () => {
  const [faqs, setFaqs] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [showReplies, setShowReplies] = useState({});
  const user = localStorage.getItem("username");
// //console.log(user);
  const fetchFaqs = async () => {
    try {
      const response = await axios.get(`https://prepmate-3uro.onrender.com/api/mocktests/query?username=${user}`);
      const fetchedFaqs = response.data;

      const updatedFaqs = fetchedFaqs.map((faq) => ({
        ...faq,
        resolved: faq.reply ? true : faq.resolved,
      }));

      setFaqs(updatedFaqs);
      setLoading(false);
      setError("");
    } catch (err) {
      console.error("Fetch FAQs error:", err.message);
      setError("Failed to fetch FAQs. Please try again.");
      setFaqs([]);
      setLoading(false);
    }
  };

  const toggleReplyVisibility = (index) => {
    setShowReplies((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  useEffect(() => {
    fetchFaqs();
  }, []);

  return (
    <div className="min-h-screen  py-12 px-6 sm:px-8 lg:px-12">
      <div className="max-w-4xl mx-auto bg-[#121212] p-8 rounded-2xl shadow-2xl transform transition-all hover:scale-105 hover:shadow-3xl">
        <h2 className="text-3xl font-extrabold text-white mb-8 tracking-tight">Query Raised By {user}</h2>

        {error && <p className="text-red-500 text-center mb-4">{error}</p>}

        {loading ? (
          <div className="flex justify-center items-center">
            <BeatLoader color="#4f46e5" />
          </div>
        ) : (
          <div className="space-y-6">
            <h3 className="text-2xl font-semibold text-gray-300 mb-4">FAQs for {user}</h3>

            {faqs.length > 0 ? (
              <ul className="space-y-6">
                {faqs.map((faq, index) => (
                  <li
                    key={index}
                    className="bg-gray-800 shadow-xl rounded-xl border border-gray-600 p-6 transition-all hover:bg-gray-700 transform duration-300 ease-in-out"
                  >
                    <div className="text-lg font-medium text-indigo-300">{faq.category}</div>
                    <p className="text-gray-300 mt-2">{faq.message}</p>
                    <div className="text-sm text-gray-400 mt-4">
                      <strong>Created at:</strong> {new Date(faq.createdAt).toLocaleDateString()}
                    </div>

                    <div className="mt-4 flex items-center space-x-4">
                      <strong>Status:</strong>
                      {faq.response ? (
                        <span className="flex items-center text-green-500">
                          <FaCheckCircle className="mr-2" /> Resolved
                        </span>
                      ) : (
                        <span className="flex items-center text-red-500">
                          <FaTimesCircle className="mr-2" /> Not Resolved
                        </span>
                      )}
                    </div>

                    {/* Toggle button for the reply */}
                    {faq.response && (
                      <div className="mt-4">
                        <button 
                          onClick={() => toggleReplyVisibility(index)}
                          className="text-white transition-all duration-200 flex items-center bg-[#876a7d] rounded-2xl"
                        >
                          {showReplies[index] ? (
                            <>
                              <button><span>Hide Reply</span></button>
                              <svg className="ml-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
                                <path d="M4.646 10.646a.5.5 0 0 1 .708 0L8 7.707l2.646 2.939a.5.5 0 0 1-.708.707L8 9.207l-2.646 2.939a.5.5 0 0 1-.708-.707L7.293 8.707l-2.646-2.939a.5.5 0 0 1 .708-.707L8 7.207l2.646-2.939a.5.5 0 0 1 .708.707L8 7.707l2.646 2.939a.5.5 0 0 1-.708.707L8 9.207l-2.646 2.939a.5.5 0 0 1-.708-.707z"/>
                              </svg>
                            </>
                          ) : (
                            <>
                              <button ><span>View Reply</span></button>
                              <svg className="ml-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                <path d="M4.646 5.354a.5.5 0 0 1 .708 0L8 7.793l2.646-2.439a.5.5 0 1 1 .708.707L8 9.207 4.646 6.622a.5.5 0 0 1 0-.707z"/>
                              </svg>
                            </>
                          )}
                        </button>
                        {showReplies[index] && (
                          <p className="mt-4 text-gray-200">{faq.response}</p>
                        )}
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-500 text-center">No FAQs found for this user.</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FaqQuery;

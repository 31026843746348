import React, { useState } from 'react';
import { FaEye, FaDownload } from 'react-icons/fa';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { BsFileEarmarkPdf, BsFileEarmarkWord } from 'react-icons/bs';

const syllabus = {
  Mathematics: [
    { id: 1, title: "Arithmetic and Number System", file: "https://study.kdcampus.live/pdf/quantitative-aptitude-number-system-questions-pdf-with-detailed-solutions.pdf" },
    { id: 2, title: "Algebra", file: "https://www.stuartschool.org/uploaded/parents/back_to_school/2022-2023_Files/2022_Upper_School_Summer/Algebra_2_Summer_Assignment_2022.pdf" },
    { id: 3, title: "Geometry", file: "https://study.kdcampus.live/pdf/quantitative-aptitude-geometry-questions-pdf-with-detailed-solutions.pdf" },
    { id: 4, title: "Trigonometry", file: "https://study.kdcampus.live/pdf/quantitative-aptitude-trigonometry-questions-download-pdf-now.pdf" },
    { id: 5, title: "Mensuration", file: "https://study.kdcampus.live/pdf/quantitative-aptitude-mensuration-questions-pdf-with-detailed-solutions.pdf" },
    { id: 6, title: "Data Interpretation", file: "https://ugcportal.com/raman-files/app/datainterpretationworkbookv3.pdf" },
  ],
  GeneralScience: [
    { id: 7, title: "Physics", file: "https://wpassets.adda247.com/wp-content/uploads/multisite/sites/2/2023/02/15171748/Physics-Notes-50-Questions-for-SSC-CGL-Tier-2-Exam.pdf" },
    { id: 8, title: "Chemistry", file: "https://wpassets.adda247.com/wp-content/uploads/multisite/sites/2/2023/02/16182526/Chemistry-Notes-50-Questions-for-SSC-CGL-Tier-2-Exam.pdf" },
    { id: 9, title: "Biology", file: "https://wpassets.adda247.com/wp-content/uploads/multisite/sites/2/2023/02/17182709/Biology-Notes-50-Questions-for-SSC-CGL-Tier-2-Exam.pdf" },
  ],
  GeneralAwareness: [
    { id: 10, title: "Indian History", file: "https://www.drishtiias.com/images/pdf/sr.%20secondary%20history.pdf" },
    { id: 11, title: "World History", file: "https://www.upscprep.com/content/files/2022/06/World-History.pdf" },
    { id: 12, title: "Indian Geography", file: "https://blogmedia.testbook.com/blog/wp-content/uploads/2023/03/important-geography-notes-for-ssc-railway-upsc-exam-pdf-78397b96.pdf" },
    { id: 13, title: "World Geography", file: "https://blogmedia.testbook.com/blog/wp-content/uploads/2023/03/important-geography-notes-for-ssc-railway-upsc-exam-pdf-78397b96.pdf" },
    { id: 14, title: "Economics", file: "https://drive.google.com/file/d/1SoVuc_PA_cYJ2wvr68Tj66I_bDcegH7h/view" },
    { id: 15, title: "Polity and Governance", file: "https://www.drishtiias.com/pdf/1713874098_Polity%20&%20Governance%202024.pdf" },
  ],
  CurrentAffairs: [
    { id: 16, title: "National Events", file: "https://static.vikaspedia.in/mediastorage/document/January_2024_National_Ca.pdf" },
    { id: 17, title: "International Relations", file: "https://www.drishtiias.com/pdf/1649674475_PT%20Sprint%202022%20(International%20Relations).pdf" },
    { id: 18, title: "Science and Technology", file: "https://cdn.visionias.in/value_added_material/1fe1a-pt_365_science_and_technology.pdf" },
    { id: 19, title: "Environmental News", file: "https://www.drishtiias.com/pdf/1714109397_PT%20Sprint%202024%20Environment%20&%20Ecology.pdf" },
  ],
  EnglishLanguage: [
    { id: 20, title: "Grammar", file: "https://ugcportal.com/raman-files/English%20Grammar%20Notes.pdf" },
    { id: 21, title: "Vocabulary", file: "https://smartyingyu.com/consultantsdocs/English%20for%20Everyone%20Vocabulary.pdf" },
    { id: 22, title: "Reading Comprehension", file: "https://www.sjsu.edu/writingcenter/docs/handouts/Reading%20Comprehension.pdf" },
  ],
  LogicalReasoning: [
    { id: 23, title: "Analytical Reasoning", file: "https://uaf.edu.pk/admissions/post/qust%20bank/Varbal%20Analytic%20and%20Quantitaive%20Reasoning.pdf" },
    { id: 24, title: "Verbal Reasoning", file: "https://www.pmtutor.org/resources/guides/Verbal_Reasoning.pdf" },
    { id: 25, title: "Non-Verbal Reasoning", file: "https://ggnindia.dronacharya.info/Study-Materials/Download/Verbal-Non-verbal-reasoning-Book.pdf" },
  ],
};



function Syllabus() {
  const [expandedCategories, setExpandedCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');

  const toggleCategory = (category) => {
    setExpandedCategories((prev) =>
      prev.includes(category)
        ? prev.filter((c) => c !== category)
        : [...prev, category]
    );
  };

  const handleSearch = (e) => setSearchTerm(e.target.value);

  const filteredSyllabus = Object.entries(syllabus).reduce((acc, [category, topics]) => {
    if (selectedCategory === 'All' || selectedCategory === category) {
      const filteredTopics = topics.filter((topic) =>
        topic.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      if (filteredTopics.length) acc[category] = filteredTopics;
    }
    return acc;
  }, {});

  return (
    <div className="bg-[#0d0c0a] text-[#fff9f8] min-h-screen p-8">
      <h2 className="text-4xl font-bold font-montserrat text-center mb-10">Study Materials</h2>

      <div className="flex flex-col md:flex-row items-center gap-6 mb-6 sticky top-0 bg-[#0d0c0a] p-4 z-10">
        <select
          onChange={(e) => setSelectedCategory(e.target.value)}
          className="bg-[#1a1917] text-white px-4 py-2 rounded-lg border border-[#4a4a49] outline-none w-full md:w-1/4"
          value={selectedCategory}
        >
          <option value="All">All Categories</option>
          {Object.keys(syllabus).map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
        <input
          type="text"
          placeholder="Search topics..."
          className="bg-[#1a1917] text-white px-4 py-2 rounded-lg border border-[#4a4a49] outline-none w-full md:w-3/4"
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>

      {Object.entries(filteredSyllabus).map(([subject, topics]) => (
        <div key={subject} className="mb-6">
          <div
            onClick={() => toggleCategory(subject)}
            className="flex justify-between items-center bg-[#1a1917] p-4 rounded-lg cursor-pointer border border-[#4a4a49]"
          >
            <h3 className="text-2xl font-semibold">{subject}</h3>
            {expandedCategories.includes(subject) ? (
              <FiChevronUp className="text-white" />
            ) : (
              <FiChevronDown className="text-white" />
            )}
          </div>

          {expandedCategories.includes(subject) && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-4">
              {topics.map((topic) => (
                <div
                  key={topic.id}
                  className="relative bg-[#1a1917] p-6 rounded-lg shadow-lg border border-[#4a4a49] transition-transform duration-300 transform hover:-translate-y-1 hover:scale-105 group"
                >
                  <div className="absolute top-2 right-2 text-sm font-semibold px-2 py-1 rounded-lg bg-[#ffffff] text-[#0d0c0a]">
                    {topic.type === "pdf" ? <BsFileEarmarkPdf /> : <BsFileEarmarkWord />}
                  </div>
                  <h4 className="text-xl font-semibold mb-2 group-hover:text-white transition-colors duration-300">
                    {topic.title}
                  </h4>

                  <div className="absolute inset-0 bg-black bg-opacity-75 opacity-0 group-hover:opacity-100 flex items-center justify-center space-x-6 transition-opacity duration-300">
                    <a
                      href={topic.file}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white text-2xl hover:scale-110 transition-transform"
                    >
                      <FaEye />
                    </a>
                    <a
                      href={topic.file}
                      download
                      className="text-white text-2xl hover:scale-110 transition-transform"
                    >
                      <FaDownload />
                    </a>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
export default Syllabus;

import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "flowbite";
import { initFlowbite } from "flowbite";
import PrepMate from "../../assets/PrepMate1.png";

const Navbar = () => {
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const dropdownRef = useRef(null); // Reference for the profile dropdown
  const mobileMenuRef = useRef(null); // Reference for the mobile menu

  useEffect(() => {
    initFlowbite();

    // Close dropdown and mobile menu when clicking outside
    const handleClickOutside = (e) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target) &&
        !mobileMenuRef.current.contains(e.target)
      ) {
        setDropdownOpen(false); // Close profile dropdown
        setMobileMenuOpen(false); // Close mobile menu
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("username");
    localStorage.removeItem("role");
    localStorage.removeItem("email");
    localStorage.removeItem("name");
    localStorage.clear();

    navigate("/login");
  };

  const isLoggedIn = Boolean(localStorage.getItem("userToken"));

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
    if (isMobileMenuOpen) setDropdownOpen(false);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false); // Close the mobile menu when a link is clicked
  };

  const closeProfileMenu = () => {
    setDropdownOpen(false); // Close the profile menu when a link is clicked
  };

  const user = localStorage.getItem("username");
  const role = localStorage.getItem("role");

  return (
    <div className="sticky top-0 z-50">
      <nav className="bg-[#0d0c0a]">
        <div className="max-w-screen-xl bg-[#0d0c0a] rounded-3xl flex border-2 border-gray-800 flex-wrap items-center justify-between mx-auto p-[0.5] sm:p-3 md:p-2">
          <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
            <span className="self-center text-2xl font-semibold whitespace-nowrap text-[#FFF9F8]">
              <img
                src={PrepMate}
                className="h-[70px] sm:h-[60px] md:h-[70px] lg:h-[60px] md:scale-[2]  ml-3 lg:scale-120 lg:pl-2 object-contain"
                alt="PrepMate Logo"
              />
            </span>
          </Link>
          <div className="flex items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
            {isLoggedIn ? (
              <>
                <div className="relative">
                  <button
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    className="relative inline-flex items-center justify-center px-4 py-2 text-sm font-semibold text-white transition-all duration-300 bg-black border-2 border-transparent rounded-full overflow-hidden group hover:bg-opacity-60 md:px-6 md:py-3 md:text-lg"
                  >
                    <span className="absolute inset-0 transition-all duration-300 bg-gradient-to-r from-purple-600 to-pink-600 opacity-30 rounded-full scale-110 group-hover:scale-125"></span>
                    <span>Welcome {user}</span>
                    <span className="absolute inset-0 border-4 border-transparent rounded-full "></span>
                  </button>

                  {dropdownOpen && (
                    <div
                      ref={dropdownRef}
                      className="absolute right-0 z-10 w-48 mt-2 bg-[#0D0C0A] border-2 border-gray-700 rounded-md shadow-lg"
                    >
                      <div className="py-2">
                        <p className="block px-4 py-2 text-sm text-[#FFF9F8]">
                          {user}
                        </p>
                        <p className="px-4 py-2 text-sm text-[#FFF9F8] max-w-full break-words">
                          {localStorage.getItem("email") || "No Email"}
                        </p>

                        <Link
                          to="/my-learning"
                          className="block px-4 py-2 text-sm text-[#FFF9F8] rounded hover:bg-gray-700"
                          onClick={closeProfileMenu} // Close the profile menu when clicked
                        >
                          My Learning
                        </Link>
                        <Link
                          to="/query"
                          className="block px-4 py-2 text-sm text-[#FFF9F8] rounded hover:bg-gray-700"
                          onClick={closeProfileMenu} // Close the profile menu when clicked
                        >
                          My Query
                        </Link>
                        {role === "admin" && (
                          <Link
                            to="/admin"
                            className="block px-4 py-2 text-sm text-[#FFF9F8] rounded hover:bg-gray-700"
                            onClick={closeProfileMenu} // Close the profile menu when clicked
                          >
                            Admin
                          </Link>
                        )}

                        <button
                          onClick={handleLogout}
                          className="block w-full text-left px-4 py-2 text-sm text-[#FFF9F8] rounded hover:bg-gray-700"
                          // onClick={closeProfileMenu} // Close the profile menu when clicked
                        >
                          Sign Out
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <Link
                  to="/signup"
                  className="block px-4 py-2 text-sm text-[#FFF9F8] rounded hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600"
                >
                  Sign Up
                </Link>

                <Link
                  to="/login"
                  className="block px-4 py-2 text-sm text-[#FFF9F8] rounded hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600"
                >
                  Login
                </Link>
              </>
            )}
          </div>

          <div className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1">
            <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-[#0d0c0a]">
              <li>
                <Link
                  to="/"
                  className="block py-2 px-3 text-[#FFF9F8] rounded md:bg-transparent md:p-0 md:hover:text-blue-700"
                  aria-current="page"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/study-materials"
                  className="block py-2 px-3 text-[#FFF9F8] rounded md:hover:text-blue-700 md:p-0"
                >
                  Study Materials
                </Link>
              </li>
              <li>
                <Link
                  to="/testimonials"
                  className="block py-2 px-3 text-[#FFF9F8] rounded md:hover:text-blue-700 md:p-0"
                >
                  Testimonials
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="block py-2 px-3 text-[#FFF9F8] rounded md:hover:text-blue-700 md:p-0"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>

          <div className="md:hidden">
            <button
              onClick={toggleMobileMenu}
              ref={mobileMenuRef}
              data-collapse-toggle="navbar-user"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="navbar-user"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                ></path>
              </svg>
            </button>
            {isMobileMenuOpen && (
              <div
                id="navbar-user"
                className="absolute right-0 z-10 w-44 mt-2 bg-[#0D0C0A] border-2 border-gray-700 rounded-md shadow-lg"
              >
                <ul className="py-2 space-y-2 text-sm text-[#FFF9F8]">
                  <li>
                    <Link
                      to="/"
                      className="block px-4 py-2 text-sm"
                      onClick={closeMobileMenu}
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/study-materials"
                      className="block px-4 py-2 text-sm"
                      onClick={closeMobileMenu}
                    >
                      Study Materials
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/testimonials"
                      className="block px-4 py-2 text-sm"
                      onClick={closeMobileMenu}
                    >
                      Testimonials
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contact"
                      className="block px-4 py-2 text-sm"
                      onClick={closeMobileMenu}
                    >
                      Contact
                    </Link>
                  </li>
                  {isLoggedIn && (
                    <button
                      onClick={handleLogout}
                      className="block w-full text-left px-4 py-2 text-sm text-[#FFF9F8] rounded hover:bg-gray-700"
                    >
                      Sign Out
                    </button>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;

import './App.css';
import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google'; // Import Google OAuth Provider
import FeaturedCourses from './components/courses/FeaturedCourses';
import HeroSection from './components/homepage/HeroSection';
import Navbar from './components/homepage/Navbar';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import TestInstructions from './components/courses/TestInstructions';
import MockTestSeries from './components/mocktest/MockTestSeries';
import TimeAndClock from './components/testseries/TimeAndClock';
import WorkAndTime from './components/testseries/WorkAndTime';
import TestAnalysis from './components/testanalysis/TestAnalysis';
import Ntpc from './components/testseries/Ntpc';
import LoginForm from './components/form/LoginForm';
import SignUp from './components/form/SignUp';
import MotionWrapper from "./animations/MotionWrapper";
import MyLearning from './components/enrollment/MyLearning';
import Accuracy from "./components/enrollment/Accuracy";
import Testimonials from './components/homepage/Testimonials';
import Footer from './components/homepage/Footer';
import Faq from './components/homepage/Faq';
import FaqQuestion from './components/homepage/FaqQuestion';
import StudyMaterials from './study/StudyMaterials';
import RecentlyAttempted from './components/testanalysis/RecentlyAttempted';
import Admin from './components/admin/Admin';
import Query from './components/admin/Query';

// Set your Google OAuth client ID here
//for online : https://prepmate-3uro.onrender.com
//for localhost https://localhost:5000
const clientId = '680130058625-in2ldlv1o9p5hom9sigpuhckmojdlt7g.apps.googleusercontent.com';

function App() {
  const location = useLocation();

  return (
    <div>
      <Navbar />
      <MotionWrapper location={location}>
        <Routes location={location}>
          <Route path="/" element={
            <>
              <HeroSection />
              <MyLearning />
              <FeaturedCourses />
              <Faq />
              <Testimonials />
              <Footer />
            </>
          } />
          <Route path="/accuracy" element={<Accuracy />} />
          <Route path="/test-instructions" element={<TestInstructions />} />
          <Route path="/test-series/:courseId" element={<MockTestSeries />} />
          <Route path='/time-and-clock' element={<TimeAndClock />} />
          <Route path='/work-and-time' element={<WorkAndTime />} />
          <Route path='/test-analysis' element={<TestAnalysis />} />
          <Route path='/all-courses' element={<FeaturedCourses />} />
          <Route path='/rrb-ntpc' element={<Ntpc />} />
          <Route path='/login' element={<LoginForm />} />
          <Route path='/signup' element={<SignUp />} />
          <Route path="/courses/test-series/:courseId" element={<MockTestSeries />} />
          <Route path='/faq' element={<FaqQuestion />} />
          <Route path='/contact' element={<FaqQuestion />} />
          <Route path='/testimonials' element={<Testimonials />} />
          <Route path='/study-materials' element={<StudyMaterials />} />
          <Route path='/courses' element={<FeaturedCourses />} />
          <Route path="*" element={<div className='flex justify-center items-center'>Page Not Found</div>} />
          <Route path="/my-learning" element={<RecentlyAttempted />} />
          <Route path='/admin' element={<Admin />} />
          <Route path='/query' element={<Query />} />
        </Routes>
      </MotionWrapper>
    </div>
  );
}

// Wrap the App component with GoogleOAuthProvider and Router
const WrappedApp = () => (
  <GoogleOAuthProvider clientId={clientId}>
    <Router>
      <App />
    </Router>
  </GoogleOAuthProvider>
);

export default WrappedApp;

import React, { useState, useEffect } from "react";
import { FaChartLine, FaUsers, FaClipboardList, FaBell } from "react-icons/fa";
import axios from "axios";
import Notification from "./Notification";
import UserManagement from "./UserManagement";
import { IoAddOutline } from "react-icons/io5";
import { IoPersonOutline } from "react-icons/io5";
import { IoSettingsOutline } from "react-icons/io5";
const DashboardCard = ({
  title,
  value,
  icon,
  bgColor,
  textColor,
  borderColor,
  onClick,
}) => (
  <div
    className={`flex items-center justify-between p-6 rounded-lg shadow-md ${bgColor} ${textColor} ${borderColor} transition transform hover:scale-105 hover:shadow-xl`}
    onClick={onClick}
  >
    <div>
      <h3 className="text-xl font-semibold">{title}</h3>
      <p className="text-2xl font-bold">{value}</p>
    </div>
    <div className="text-4xl">{icon}</div>
  </div>
);

const Admin = () => {
  const [activeTab, setActiveTab] = useState("overview");
  const [userCount, setUserCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showNotifications, setShowNotifications] = useState(false);

  useEffect(() => {
    const fetchUserCount = async () => {
      try {
        const response = await axios.get(
          "https://prepmate-3uro.onrender.com/api/mocktests/usercount"
        );
        setUserCount(response.data.count);
      } catch (error) {
        console.error("Error fetching user count:", error);
      }
    };
    fetchUserCount();
  }, []);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(
          "https://prepmate-3uro.onrender.com/api/mocktests/notifications"
        );
        setNotifications(response.data.notice);
      } catch (error) {
        console.error("An error occurred while fetching notifications:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchNotifications();
  }, []);

  const toggleNotifications = () => {
    setShowNotifications((prevShow) => !prevShow);
  };

  return (
    <div className="bg-[#0d0c0a] min-h-screen p-6 text-[#fff9f8]">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-bold mb-8 text-center">Welcome Admin</h1>

        {/* Dashboard Cards */}
        <div className="grid gap-6 lg:grid-cols-4 sm:grid-cols-2 xs:grid-cols-1">
          <DashboardCard
            title="Users"
            value={userCount}
            icon={<FaUsers />}
            bgColor="bg-[#6366F1]/30"
            textColor="text-[#6366F1]"
            borderColor="border-[#6366F1]/40"
          />
          <DashboardCard
            title="Active Sessions"
            value="350"
            icon={<FaChartLine />}
            bgColor="bg-[#EC4899]/30"
            textColor="text-[#EC4899]"
            borderColor="border-[#EC4899]/40"
          />
          <DashboardCard
            title="Tasks Pending"
            value="72"
            icon={<FaClipboardList />}
            bgColor="bg-[#10B981]/30"
            textColor="text-[#10B981]"
            borderColor="border-[#10B981]/40"
          />
          <DashboardCard
            title="Notifications"
            value={notifications.length}
            icon={<FaBell />}
            bgColor="bg-[#F59E0B]/30"
            textColor="text-[#F59E0B]"
            borderColor="border-[#F59E0B]/40"
            onClick={toggleNotifications}
          />
        </div>

        <div className="mt-10">
          {/* Tab Navigation */}
          <div className="flex flex-wrap justify-between lg:space-x-6 sm:space-x-2 sm:space-y-2 border-b border-[#6366F1]/30 pb-4">
            <button
              onClick={() => setActiveTab("userManagement")}
              className={`text-lg font-semibold py-2 px-4  transition-all hover:text-[#6366F1] flex items-center space-x-2 ${
                activeTab === "userManagement"
                  ? "border-b-4 border-[#6366F1]"
                  : ""
              }`}
            >
              <IoPersonOutline className="text-2xl" />{" "}
              <span>User Management</span>
            </button>
            <button
              onClick={() => setActiveTab("analytics")}
              className="px-4 py-2 rounded-full text-sm font-medium bg-indigo-600 text-white hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 transition-all duration-300 ease-in-out flex items-center space-x-2 group"
            >
              {/* Add Icon with hover effect */}
              <IoAddOutline className="text-xl transition-transform duration-300 ease-in-out group-hover:scale-110" />

              {/* Text */}
              <span>Add New</span>
            </button>
            <button
              onClick={() => setActiveTab("settings")}
              className={`text-lg font-semibold py-2 px-4 flex items-center space-x-2 transition-all duration-300 ease-in-out hover:text-[#6366F1] ${
                activeTab === "settings" ? "border-b-4 border-[#6366F1]" : ""
              }`}
            >
              {/* Settings Icon */}
              <IoSettingsOutline className="text-xl transition-transform duration-300 ease-in-out" />

              {/* Text */}
              <span>Settings</span>
            </button>
          </div>

          {/* Tabs Content */}
          <div className="mt-6">
            {activeTab === "userManagement" && <UserManagement />}

            {activeTab === "analytics" && (
              <div className="space-y-4">
                <h2 className="text-2xl font-bold">Analytics</h2>
                <div className="text-[#A1A1AA]">
                  Track the platform's growth and performance.
                </div>
              </div>
            )}

            {activeTab === "settings" && (
              <div className="space-y-4">
                <h2 className="text-2xl font-bold">Settings</h2>
                <div className="text-[#A1A1AA]">
                  Configure platform preferences and settings.
                </div>
              </div>
            )}
          </div>

          {/* Notifications Section */}
          {showNotifications && (
            <div className="mt-8">
              <Notification notifications={notifications} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Admin;

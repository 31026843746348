import React, { useEffect, useState } from 'react';
import axios from 'axios';

const UserManagement = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [users, setUsers] = useState([]);
  const usersPerPage = 6;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const res = await axios.get('https://prepmate-3uro.onrender.com/api/mocktests/userdetails');
        setUsers(res.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchUsers();
  }, []);

  const handleShowCourses = async (user) => {
    setSelectedUser(null);
    try {
      const res = await axios.get(`https://prepmate-3uro.onrender.com/api/mocktests/coursedetails?username=${user.username}`);
      setSelectedUser({
        ...user,
        view: 'courses',
        courses: res.data.enrollments.length > 0 ? res.data.enrollments : null,
      });
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  const handleShowTests = async (user) => {
    setSelectedUser(null); 
    try {
      const res = await axios.get(`https://prepmate-3uro.onrender.com/api/mocktests/courseattempted/${user.username}`);
      setSelectedUser({
        ...user,
        view: 'tests',
        tests: res.data.length > 0 ? res.data : null,
      });
    } catch (error) {
      console.error('Error fetching tests:', error);
    }
  };

  const handleDeleteUser = async (username) => {
   
    const isConfirmed = window.confirm('Are you sure you want to delete this user?');
    if (!isConfirmed) return;
  
    try {
      const res = await axios.delete(`https://prepmate-3uro.onrender.com/api/mocktests/deleteuser/${username}`);
      if (res.status === 200) {
        setUsers(users.filter(user => user.username !== username)); 
        setSelectedUser(null); 
        alert('User has been successfully deleted.');
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      alert('An error occurred while deleting the user.'); 
    }
  };
  

  const filteredUsers = users.filter(user =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);
  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  return (
    <div className="text-[#fff9f8] p-8 min-h-screen">
      <div className="max-w-7xl mx-auto space-y-8">
        {/* Search Input */}
        <div className="flex justify-center mb-8">
          <div className="relative w-full md:w-1/2 lg:w-1/3">
            <input
              type="text"
              placeholder="Search for a user..."
              className="p-4 w-full bg-[#1f1f1f] text-white border border-transparent focus:outline-none focus:ring-2 focus:ring-indigo-500 transition-all duration-300 placeholder-gray-500 rounded-3xl"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        {/* User List */}
        <div className="space-y-4">
          {currentUsers.map((user) => (
            <div key={user.id} className="bg-[#1f1f1f] border border-gray-700 text-[#fff9f8] p-4 rounded-2xl flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
              <div className="flex flex-col text-center md:text-left">
                <h3 className="text-lg font-semibold">{user.name}</h3>
                <p className="text-sm">{user.email}</p>
              </div>
              <div className="flex space-x-4">
                <button onClick={() => handleShowCourses(user)} className="bg-blue-500 text-white px-4 py-2 rounded-lg text-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500">
                  Show Enrolled Courses
                </button>
                <button onClick={() => handleShowTests(user)} className="bg-green-500 text-white px-4 py-2 rounded-lg text-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500">
                  Show Test Attempted
                </button>
                <button onClick={() => handleDeleteUser(user.username)} className="bg-red-500 text-white px-4 py-2 rounded-lg text-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500">
                  Remove User
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* Pagination Controls */}
        <div className="flex justify-center space-x-4 mt-6">
          <button
            className={`px-6 py-2 rounded-full text-sm font-medium ${currentPage === 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-indigo-500 text-white hover:bg-indigo-600 focus:ring-2 focus:ring-indigo-500'}`}
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            &lt; Prev
          </button>
          <div className="flex space-x-2">
            {[...Array(totalPages).keys()].map(pageNumber => (
              <button
                key={pageNumber}
                onClick={() => setCurrentPage(pageNumber + 1)}
                className={`px-4 py-2 rounded-full text-sm font-medium ${currentPage === pageNumber + 1 ? 'bg-indigo-500 text-white' : 'bg-white text-indigo-500 border border-indigo-500 hover:bg-indigo-50 focus:ring-2 focus:ring-indigo-500'}`}
              >
                {pageNumber + 1}
              </button>
            ))}
          </div>
          <button
            className={`px-6 py-2 rounded-full text-sm font-medium ${currentPage === totalPages ? 'bg-gray-300 cursor-not-allowed' : 'bg-indigo-500 text-white hover:bg-indigo-600 focus:ring-2 focus:ring-indigo-500'}`}
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next &gt;
          </button>
        </div>

        {/* User Details */}
        {selectedUser && (
          <div className="mt-8 border border-gray-700 bg-[#1f1f1f] text-[#fff9f8] rounded-lg shadow-lg p-8 space-y-6">
            <h2 className="text-2xl font-semibold">User Details</h2>
            {selectedUser.view === 'courses' ? (
              selectedUser.courses ? (
                <div>
                  <h3 className="text-xl font-medium mb-3">Enrolled Courses</h3>
                  <div className="space-y-6">
                    {selectedUser.courses.map((course, index) => (
                      <div key={index} className="bg-[#2e2e2e] p-4 rounded-lg shadow-md">
                        <h4 className="text-lg font-semibold">{course.courseId.title}</h4>
                        <p className="text-sm text-gray-300 mb-3">Price: {course.courseId.price}</p>
                        {course.courseId.lessons && course.courseId.lessons.length > 0 ? (
                          <div className="space-y-2">
                            <h5 className="text-md font-medium mb-2">Lessons:</h5>
                            <ul className="list-disc pl-6">
                              {course.courseId.lessons.map((lesson) => (
                                <li key={lesson._id} className="text-sm text-gray-400">
                                  {lesson.lessonName} - Duration: {lesson.duration}
                                </li>
                              ))}
                            </ul>
                          </div>
                        ) : (
                          <p className="text-gray-400">No lessons available for this course.</p>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <p className="text-gray-400">No courses enrolled.</p>
              )
            ) : selectedUser.view === 'tests' ? (
              <div>
                <h3 className="text-xl font-medium mb-3">Previously Attempted Tests</h3>
                {selectedUser.tests ? (
                  <div className="space-y-4">
                    {selectedUser.tests.map((test, index) => (
                      <div key={index} className="bg-[#2e2e2e] p-4 rounded-lg shadow-md">
                        <h4 className="text-lg font-semibold">{test.title}</h4>
                        <p className="text-sm text-gray-300">Test Date: {new Date().toLocaleDateString()}</p>
                        <p className="text-sm text-gray-300">Result: {test.score}</p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-gray-400">No tests attempted.</p>
                )}
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserManagement;

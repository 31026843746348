import React, { useState, useEffect } from 'react';
import { FaBell } from 'react-icons/fa';
import axios from 'axios';

const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [replies, setReplies] = useState({});
  const [replyLoading, setReplyLoading] = useState({});
  const [deleteLoading, setDeleteLoading] = useState({});

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get('https://prepmate-3uro.onrender.com/api/mocktests/notifications');
        setNotifications(response.data.notice);
      } catch (error) {
        setError('An error occurred while fetching notifications');
      } finally {
        setLoading(false);
      }
    };
    fetchNotifications();
  }, []);

  const handleDelete = async (id) => {
    setDeleteLoading((prev) => ({ ...prev, [id]: true }));
    try {
      await axios.delete(`https://prepmate-3uro.onrender.com/api/mocktests/notifications/${id}`);
      setNotifications((prevNotifications) =>
        prevNotifications.filter((notification) => notification._id !== id)
      );
    } catch (error) {
      console.error('Error deleting notification:', error);
      setError('An error occurred while deleting the notification');
    } finally {
      setDeleteLoading((prev) => ({ ...prev, [id]: false }));
    }
  };

  const handleReplyChange = (id, reply) => {
    setReplies((prevReplies) => ({
      ...prevReplies,
      [id]: reply,
    }));
  };

  const handleSubmitReply = async (id) => {
    const reply = replies[id];
    if (!reply) return;

    setReplyLoading((prev) => ({ ...prev, [id]: true }));
    try {
      // Find the notification by id to get the message
      const notification = notifications.find((notification) => notification._id === id);
      if (!notification) return;

      // Send the id, message, and response (reply) to the backend
      await axios.post(`https://prepmate-3uro.onrender.com/api/mocktests/notifications/${id}`, {
        id: notification._id,           // Send the notification ID
        message: notification.message,  // Send the notification message
        response: reply,                // Send the reply (response)
      });

      // Update the local state with the response after the reply is successfully submitted
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification._id === id ? { ...notification, response: reply } : notification
        )
      );

      // Store the replied notification in local storage
      const repliedNotifications = JSON.parse(localStorage.getItem('repliedNotifications')) || [];
      repliedNotifications.push({
        id: notification._id,
        reply: reply,
      });
      localStorage.setItem('repliedNotifications', JSON.stringify(repliedNotifications));

      // Clear the reply input after submission
      setReplies((prevReplies) => ({ ...prevReplies, [id]: '' }));
    } catch (error) {
      console.error('Error submitting reply:', error);
      setError('An error occurred while submitting the reply');
    } finally {
      setReplyLoading((prev) => ({ ...prev, [id]: false }));
    }
  };

  if (loading) return <p className="text-center text-gray-400 animate-pulse">Loading notifications...</p>;
  if (error) return <p className="text-center text-red-500">{error}</p>;

  return (
    <div className="min-h-screen p-6 text-[#F9F9F9]">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center gap-3 mb-8">
          <FaBell className="text-[#6A5ACD] text-5xl soft-glow" />
          <h2 className="text-4xl font-bold text-[#F0E5FF]">Latest Notifications</h2>
        </div>

        {notifications.length === 0 ? (
          <p className="text-center bg-[#1f1f1f] text-gray-500 text-lg">No notifications available</p>
        ) : (
          <ul className="space-y-6">
            {notifications.map((notification) => (
              <li
                key={notification._id}
                className="relative p-6 rounded-lg shadow-xl backdrop-blur-lg border border-[#6A5ACD] transition-transform transform hover:scale-105 hover:shadow-2xl"
              >
                <div className="flex justify-between items-center mb-4">
                  <h4 className="text-xl font-semibold text-[#FFB74D]">
                    Category: {notification.category}
                  </h4>
                  <span className="text-xs text-gray-300">
                    {new Date(notification.createdAt).toLocaleDateString()}
                  </span>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <p><span className="font-semibold text-[#B19CD9]">Name:</span> {notification.name}</p>
                  <p className="break-words"><span className="font-semibold text-[#B19CD9]">Email:</span> {notification.email}</p>
                  <p><span className="font-semibold text-[#B19CD9]">Phone:</span> {notification.phone}</p>
                  <p><span className="font-semibold text-[#B19CD9]">Message:</span> {notification.message}</p>
                </div>

                {/* Display the existing reply */}
                {notification.response && (
                  <div className="mt-4">
                    <p className="text-[#B19CD9] font-semibold">Response:</p>
                    <p className="text-gray-300">{notification.response}</p>
                  </div>
                )}

                {/* Reply Section */}
                <div className="mt-4">
                  <textarea
                    value={replies[notification._id] || ''}
                    onChange={(e) => handleReplyChange(notification._id, e.target.value)}
                    placeholder="Type your reply here"
                    className="w-full p-3 bg-gray-700 text-white rounded-lg border border-[#6A5ACD] focus:outline-none"
                  />
                  <div className="flex justify-end mt-2">
                    <button
                      onClick={() => handleSubmitReply(notification._id)}
                      className="bg-[#6A5ACD] hover:bg-[#7A6DED] text-white font-semibold px-6 py-2 rounded-lg shadow-md transform hover:scale-110 transition-transform duration-300"
                      disabled={replyLoading[notification._id]}
                    >
                      {replyLoading[notification._id] ? 'Submitting...' : 'Submit Reply'}
                    </button>
                  </div>
                </div>

                <div className="flex justify-end mt-6">
                  <button
                    className="bg-[#6A5ACD] hover:bg-[#7A6DED] text-white font-semibold px-6 py-2 rounded-lg shadow-md transform hover:scale-110 transition-transform duration-300"
                    onClick={() => handleDelete(notification._id)}
                    disabled={deleteLoading[notification._id]}
                  >
                    {deleteLoading[notification._id] ? 'Marking...' : 'Mark as Resolved'}
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Notification;

import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';

const FaqQuestion = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: "I am unable to login. What should I do?",
      answer: "Please try resetting your password or contact support if the issue persists."
    },
    {
      question: "How can I join the Discord server after purchasing the course?",
      answer: "After purchasing, you'll receive an email with the invite link to join the server."
    },
    {
      question: "I have not received any email to join the Discord server. What should I do?",
      answer: "Check your spam folder, and if you still don’t see it, contact our support team."
    },
    {
      question: "I mistakenly bought the course and need a refund. What is the refund policy?",
      answer: "Refund requests can be submitted within 7 days of purchase. Contact support for assistance."
    },
    {
      question: "How can I access a lecture of the course?",
      answer: "Log in to your account and navigate to 'My Courses' to access your lectures."
    },
    {
      question: "What should I do if I am facing a lag issue in video recordings?",
      answer: "Ensure a stable internet connection, and try lowering the video quality if the issue persists."
    },
  ];

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    category: '',
    message: '',
  });

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const username = localStorage.getItem('username');  // Get username here

    const formWithUsername = { ...formData, username };  // Add username to the form data

    try {
      const response = await fetch('https://prepmate-3uro.onrender.com/api/mocktests/faq', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formWithUsername),  // Submit form data with username
      });

      if (!response.ok) {
        const errorData = await response.json();
        alert("An error occurred: " + errorData.error);
        return;
      }

      const data = await response.json();
      alert(data.message);

      // Reset form data after submission
      setFormData({
        name: '',
        email: '',
        phone: '',
        category: '',
        message: '',
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="flex flex-col md:flex-row p-6 bg-[#0d0c0a] text-white min-h-screen space-x-0 md:space-x-6">
      {/* FAQ Section */}
      <div className="md:w-1/2 p-4">
        <h2 className="text-3xl font-bold mb-4 text-blue-500">Frequently Faced Issues</h2>
        <ul className="space-y-4">
          {faqs.map((faq, index) => (
            <li key={index} className="border-b border-gray-700 pb-4 flex flex-col">
              <div className="flex justify-between items-center">
                <h3
                  className="font-semibold text-lg flex-1 cursor-pointer hover:text-blue-500 transition-colors"
                  onClick={() => handleToggle(index)}
                >
                  {faq.question}
                </h3>
                <span className="ml-4 text-blue-500">
                  {openIndex === index ? <FaMinus /> : <FaPlus />}
                </span>
              </div>
              {openIndex === index && (
                <p className="bg-gray-800 mt-2 p-4 rounded-lg shadow-md">{faq.answer}</p>
              )}
            </li>
          ))}
        </ul>
      </div>

      {/* Contact Form Section */}
      <div className="md:w-1/2 p-4 bg-[#141414] rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-6 text-center text-white">Send us a message</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-white mb-1" htmlFor="name">Name<span className="text-red-500">*</span></label>
            <input
              type="text"
              id="name"
              value={formData.name}
              onChange={handleChange} 
              className="w-full p-2 rounded-lg bg-gray-800 text-white"
              placeholder="Enter your name"
              required
            />
          </div>
          <div>
            <label className="block text-white mb-1" htmlFor="email">Email<span className="text-red-500">*</span></label>
            <input
              type="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-2 rounded-lg bg-gray-800 text-white"
              placeholder="Enter your email"
              required
            />
          </div>
          <div>
            <label className="block text-white mb-1" htmlFor="phone">Phone Number<span className="text-red-500">*</span></label>
            <input
              type="tel"
              id="phone"
              value={formData.phone}
              onChange={handleChange} 
              className="w-full p-2 rounded-lg bg-gray-800 text-white"
              placeholder="Enter your phone number"
              required
            />
          </div>
          <div>
            <label className="block text-white mb-1" htmlFor="category">Category<span className="text-red-500">*</span></label>
            <select
              id="category"
              value={formData.category}
              onChange={handleChange} 
              className="w-full p-2 rounded-lg bg-gray-800 text-white"
              required
            >
              <option value="">Select a category</option>
              <option value="Not able to login">Not able to login</option>
              <option value="Issue with Discord invite">Issue with Discord invite</option>
              <option value="Refund request">Refund request</option>
              <option value="Accessing course content">Accessing course content</option>
              <option value="Video playback issues">Video playback issues</option>
              <option value="Other Please Specify in the text area">Other Please Specify in the text area</option>
            </select>
          </div>
          <div>
            <label className="block text-white mb-1" htmlFor="message">Message<span className="text-red-500">*</span></label>
            <textarea
              id="message"
              value={formData.message}
              onChange={handleChange}
              className="w-full p-2 rounded-lg bg-gray-800 text-white"
              rows="4"
              placeholder="Enter your message"
              required
            ></textarea>
          </div>
          <div className="flex items-center">
            <input type="checkbox" id="captcha" className="mr-2" required />
            <label htmlFor="captcha" className="text-white">I'm not a robot</label>
          </div>
          <button type="submit" className="w-full py-2 bg-blue-600 rounded-lg text-white hover:bg-blue-700 transition-colors">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default FaqQuestion;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa"; // Importing a search icon

const RecentlyAttempted = () => {
  const [tests, setTests] = useState([]);
  const [filteredTests, setFilteredTests] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAttemptedTests = async () => {
      const username = localStorage.getItem("username");
      if (!username) {
        setError("Username not found in localStorage");
        return;
      }

      try {
        const response = await axios.get(
          `https://prepmate-3uro.onrender.com/api/mocktests/attempted`,
          {
            params: { username },
          }
        );

        if (response.data && Array.isArray(response.data.data)) {
          setTests(response.data.data);
          setFilteredTests(response.data.data);
        } else {
          setError("Unexpected response format");
          setTests([]);
          setFilteredTests([]);
        }
      } catch (error) {
        setError("Error fetching test data");
      }
    };

    fetchAttemptedTests();
  }, []);

  useEffect(() => {
    const results = tests.filter(test =>
      test.testName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredTests(results);
  }, [searchTerm, tests]);

  const handleStartTest = (testName) => {
    const lowerCaseTestName = testName.toLowerCase();
    if (lowerCaseTestName === 'time and clock') {
      navigate('/time-and-clock');
    }
  };

  return (
    <div className="w-full p-4">
      {/* <h2 className="text-3xl text-center font-bold text-orange-500 mb-6">
        Recently Attempted Tests
      </h2> */}
      <div className="min-h-screen px-12 flex flex-col items-center">
        {error && <p className="text-red-500 mb-4">{error}</p>}

       
        <div className="relative mb-4 w-full max-w-md">
          <input
            type="text"
            placeholder="Search for a tests..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="p-3 w-full rounded-lg border border-gray-700 bg-black text-white  focus:outline-none focus:border-orange-100 transition duration-300 ease-in-out shadow-lg"
          />
          <span className="absolute right-3 top-3.5 text-gray-500">
            <FaSearch />
          </span>
        </div>
        {filteredTests && filteredTests.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 w-full">
            {filteredTests.map((test, index) => (
              <div
                key={index}
                className="bg-[#1f1f1f] text-white  p-4 border rounded-3xl border-gray-700 shadow-lg flex flex-col space-y-4"
              >
                <p className="text-lg font-semibold text-green-300">
                  {test.courseTitle}
                </p>
                <p className="text-lg font-semibold text-gray-200">
                  {test.testName}
                </p>
                <p className="text-sm text-gray-400">
                  Score:{" "}
                  <span className="text-green-400 font-bold">
                    {test.score}
                  </span>
                </p>
                <p className="text-sm text-gray-400">
                  Accuracy:{" "}
                  <span className="text-green-400 font-bold">
                    {test.accuracy}%
                  </span>
                </p>
                <button
                  onClick={() => handleStartTest(test.testName)}
                  className="bg-gray-700 text-white font-semibold py-2 rounded-lg hover:bg-gray-600 transition duration-300 ease-in-out"
                >
                  Reattempt
                </button>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-gray-400 mt-4">No tests found.</p>
        )}
      </div>
    </div>
  );
};

export default RecentlyAttempted;

import React, { useState } from "react";
import { FaBookOpen, FaCalendarAlt, FaRocket } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import axios from "axios";

const Button = ({ children, className, ...props }) => (
  <button
    className={`rounded-xl border-[#27272A] bg-[#18181B] py-4 text-[#E4E4E7] hover:bg-[#27272A] transition duration-200 ${className}`}
    {...props}
  >
    {children}
  </button>
);

const Card = ({ children, className }) => (
  <div
    className={`relative overflow-hidden rounded-2xl bg-[#141414] p-6 ${className}`}
  >
    {children}
  </div>
);

export default function LoginForm() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleGoogleLoginSuccess = (credentialResponse) => {
    try {
      
      const { credential } = credentialResponse;

      const userInfo = JSON.parse(atob(credential.split(".")[1]));

      const email = userInfo.email || "";
      const username = email.split("@")[0].slice(0, 10) || "User";

      localStorage.setItem("userToken", credential);
      localStorage.setItem("username", username);
      localStorage.setItem("email", email);


      setSuccess("Login successful! Redirecting...");
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error) {
      setError("Google login failed. Please try again.");
    }
  };

  const handleGoogleLoginError = () => {
    setError("Google login failed. Please try again.");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.username || !formData.password) {
      setError("Both fields are required.");
      return;
    }

    try {
      // Send login data to backend API
      const response = await axios.post(
        "https://prepmate-3uro.onrender.com/api/mocktests/login",
        formData
      );
      const { token, role, email, username } = response.data;

      localStorage.setItem("userToken", token);
      localStorage.setItem("role", role);
      localStorage.setItem("email", email);
      localStorage.setItem("username", username);
      setSuccess("Login successful! Redirecting...");
      setTimeout(() => {
        navigate("/"); // Redirect to dashboard or home page
      }, 2000);
    } catch (error) {
      // Handle error cases
      if (error.response && error.response.status === 401) {
        setError("Invalid login credentials.");
      } else {
        setError("An error occurred. Please try again.");
      }
    }
  };

  return (
    <div className="min-h-screen bg-[#0d0c0a] flex items-center justify-center p-6">
      <div className="mx-auto max-w-7xl">
        <div className="grid gap-8 lg:grid-cols-2 lg:gap-12">
          <div className="space-y-6">
            {/* Cards */}
            <Card>
              <div className="flex items-center gap-4">
                <div className="flex h-12 w-12 items-center justify-center rounded-full bg-[#6366F1]/20">
                  <FaBookOpen className="h-6 w-6 text-[#6366F1]" />
                </div>
                <h2 className="text-lg font-semibold text-[#6366F1]">
                  Learn: Access 100+ Courses
                </h2>
              </div>
            </Card>
            <Card>
              <div className="flex items-center gap-4">
                <div className="flex h-12 w-12 items-center justify-center rounded-full bg-[#EC4899]/20">
                  <FaCalendarAlt className="h-6 w-6 text-[#EC4899]" />
                </div>
                <h2 className="text-lg font-semibold text-[#EC4899]">
                  Apply: Build, Play, Create
                </h2>
              </div>
            </Card>
            <Card>
              <div className="flex items-center gap-4">
                <div className="flex h-12 w-12 items-center justify-center rounded-full bg-[#10B981]/20">
                  <FaRocket className="h-6 w-6 text-[#10B981]" />
                </div>
                <h2 className="text-lg font-semibold text-[#10B981]">
                  Grow: Elevate Your Career
                </h2>
              </div>
            </Card>
          </div>

          {/* Login Form */}
          <Card className="p-8">
            <div className="space-y-6">
              <h1 className="text-3xl font-bold text-center">
                Welcome Back To PrepMate
              </h1>
              <p className="text-[#A1A1AA] text-center">
                Continue Your Preparation With PrepMate.
              </p>

              {/* Success or Error message */}
              {success && (
                <div className="text-green-500 text-center">{success}</div>
              )}
              {error && <div className="text-red-500 text-center">{error}</div>}

              {/* Google Login Button */}
              <GoogleLogin
                onSuccess={handleGoogleLoginSuccess}
                onError={handleGoogleLoginError}
              />

              {/* Regular Login Form */}
              <form onSubmit={handleSubmit} className="space-y-4 mt-4">
                <div>
                  <label
                    htmlFor="username"
                    className="block text-sm text-[#A1A1AA]"
                  >
                    Username
                  </label>
                  <input
                    type="text"
                    id="username"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    className="w-full rounded-xl border bg-[#18181B] text-[#E4E4E7] px-4 py-2 focus:outline-none focus:ring-2 focus:ring-[#6366F1]"
                  />
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm text-[#A1A1AA]"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    className="w-full rounded-xl border bg-[#18181B] text-[#E4E4E7] px-4 py-2 focus:outline-none focus:ring-2 focus:ring-[#6366F1]"
                  />
                </div>
                <Button type="submit" className="w-full bg-blue-800">
                  Login
                </Button>
              </form>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}
